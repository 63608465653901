<template>
  <div>
    <div class="flex xs12 pb-5">
      <p class="display-1 text--center mb-2">
        {{ $t("auth.reset_password_title") }}
      </p>
      <p class="display-2 text--center">{{ $t("auth.reset_password") }}</p>
    </div>

    <form v-if="!resetDone" @submit.prevent="onsubmit">
      <va-input
        class="mb-3"
        v-model="resetForm.email"
        type="email"
        :label="$t('auth.email')"
        :error="!!emailErrors.length"
        :error-messages="emailErrors"
      />

      <va-input
        class="mb-3"
        v-model="resetForm.fullname"
        type="text"
        :label="$t('auth.fullname')"
        :error="!!nameErrors.length"
        :error-messages="nameErrors"
      />

      <div class="row justify--center mt-3">
        <va-button type="submit" class="my-0">{{
          $t("auth.submit")
        }}</va-button>
      </div>
    </form>
    <div v-if="resetDone">
      <div class="flex xs12 pb-5">
        <p class="display-2 text--center">
          {{ $t("auth.password_reset_done") }}
        </p>
        <p class="display-2 text--center">{{ $t("auth.email_sent") }}</p>
      </div>
      <div class="row justify--center mt-3">
        <va-button class="my-0" @click="redirectBack">{{
          $t("auth.back_to_page")
        }}</va-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recover-password",
  data() {
    return {
      resetForm: {
        email: "",
        fullname: "",
      },
      emailErrors: [],
      nameErrors: [],
      resetDone: false,
    };
  },
  props: ["redirect"],
  computed: {
    formReady() {
      return !this.emailErrors.length && !this.nameErrors.length;
    },
  },
  methods: {
    onsubmit() {
      this.emailErrors = this.resetForm.email
        ? []
        : [this.$t("auth.email_not_present")];
      this.nameErrors = this.resetForm.fullname
        ? []
        : [this.$t("auth.fullname_not_present")];
      if (!this.formReady) {
        return;
      }
      this.backend
        .sendResetPasswordEmail(this.resetForm, this.$i18n.locale)
        .then((result) => {
          if (result === false) {
            // console.log('wrong input data')
            this.launchToast(
              this.$t("auth.user_password_reset_wrong"),
              "warning"
            );
          } else {
            // console.log('reset done')
            // console.log('redirect to ' + this.redirect)
            this.resetDone = true;
          }
        })
        .catch((err) => {
          // console.log('error getting response')
          this.launchToast(this.$t("auth.user_password_reset_error"), "danger");
        });
    },
    redirectBack() {
      // console.log('redirect to ' + this.redirect)
      window.location.href = this.redirect;
    },
    launchToast(text, col) {
      this.$vaToast.init({
        message: text,
        position: "top-left",
        duration: 4000,
        fullWidth: false,
        color: col,
      });
    },
  },
};
</script>

<style lang="scss"></style>
